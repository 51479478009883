/**
 * Created by rbarcante on 9/5/17.
 */
import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import { Iterable } from "immutable";
import thunk from "redux-thunk";
import reducer from "./reducers";

let middleware = applyMiddleware(thunk);

if (process.env.NODE_ENV === 'development') {

    // Logger simplificado ao utilizar Immutable
    const logger = createLogger({
        stateTransformer: (state) => {
            let newState = {};

            for (var i of Object.keys(state)) {
                if (Iterable.isIterable(state[i])) {
                    newState[i] = state[i].toJS();
                } else {
                    newState[i] = state[i];
                }
            };

            return newState;
        }
    });

    middleware = applyMiddleware(thunk, logger);
}
const store = createStore(reducer, middleware);

export default store;
