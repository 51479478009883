const Control = {
    /*
    FUNCTION:
    formatNumber
    PARAMETERS:
    number: number formatting
    s1: total of decimal places
    s2 and s3: point or comma
    */
    formatNumber: (number, s1, s2, s3) => {
        number = number.toFixed(s1) + '';
        let x = number.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? s3 + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + s2 + '$2');
        }
        return x1 + x2;
    },

    interpolateColors: (color1, color2, steps) => {
        function interpolateColor(color1, color2, factor) {
            if (arguments.length < 3) {
                factor = 0.5;
            }
            var result = color1.slice();
            for (var i = 0; i < 3; i++) {
                result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
            }
            return result;
        }

        var stepFactor = 1 / (steps - 1),
            interpolatedColorArray = [];

        color1 = color1.match(/\d+/g).map(Number);
        color2 = color2.match(/\d+/g).map(Number);

        for (var i = 0; i < steps; i++) {
            interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
        }
        return interpolatedColorArray;
    }
}

module.exports = Control;
