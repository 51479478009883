/**
 * Created by rbarcante on 9/8/17.
 */
import { Map } from "immutable";
import * as types from "../constants/ActionTypes";

const INITIAL_STATE = Map({
    opened: true,
    categoria: 'indicadores',
    field: Map({}),
    header: 'clima',
    indicadores: Map({}),
    checkBoxesForFiltro: Map({}),
    params: Map({}),
});

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.dados.CHECK_BOXES: {
            return state.merge(action.payload)
        }
        case types.slider.LAYER_ANUAL: {
            if (action.payload && !action.payload.checked) {
                // ajuda a remover os gráficos de dados
                return state.removeIn(["field", action.payload.header, "dados"])
            }
            return state;
        }
        case types.reset.INDICADORES: {
            return state.set('indicadores', Map({}));
        }
        case types.leftBox.OPEN: {
            return state.set('opened', true);
        }
        case types.leftBox.CLOSE: {
            return state.set('opened', false);
        }
        case types.leftBox.CLIMA: {
            return state.merge({
                header: "clima",
            });
        }
        case types.leftBox.GOVERNANCA: {
            return state.merge({
                header: "governanca",
            });
        }
        case types.leftBox.ANTROPICA: {
            return state.merge({
                header: "antropica",
            });
        }
        case types.leftBox.DESENVECONOM: {
            return state.merge({
                header: "desenvEconom",
            });
        }
        case types.leftBox.dados: {
            return state.merge({
                categoria: 'dados',
            });
        }
        case types.leftBox.indicadores: {
            return state.merge({
                categoria: 'indicadores',
            });
        }
        case types.dados.PENDING:
        case types.dados.MAPAS:
        case types.dados.TOGGLE: {
            return state.merge({
                field: action.payload.field
            })
        }
        case types.dados.INDICADORES:
        case types.dados.INDICE_UC: {
            return state.merge(action.payload);
        }
        case types.dados.ORIGIN_DATA: {
            return state.merge(action.payload);
        }
        case types.dados.PARAMS: {
            return state.merge({
                params: action.payload
            });
        }
        case types.reset.DADOS: {
            return state.merge(INITIAL_STATE);
        }
        default:
            return state;
    }
};
