/**
 * Created by rbarcante on 9/6/17.
 */
import { Map } from "immutable";
import * as types from "../constants/ActionTypes";

export default function reducer(state=Map({
    loading: false,
    loaded: false,
    idUcMaisDesmatou: null,
}),action) {
    switch (action.type){
        case types.map.SET_MAP:{
            return state.set('map',action.payload)
        }
        case types.map.LOAD_START:{
            return state.merge({loading:true,loaded:false})
        }
        case types.map.LOAD_END:{
            return state.merge({loading:false,loaded:true})
        }
        case types.feature.MAIS_DESMATOU:{
            return state.merge({idUcMaisDesmatou: action.payload})
        }
        default:
            return state;
    }
}