/**
 * Created by rbarcante on 9/6/17.
 */
import { Map, List, Set } from "immutable";
import * as types from "../constants/ActionTypes";
import { interpolateColors } from '../plugins/Control';

const INITIAL_STATE = Map({
    open: false,
    visibility: false,
    error: null,
    data: List([
        Map({})
    ]),
    slider: List([]),
    sliderVal: List([]),
    inicio: null,
    fim: null,
    periodo: "historico",
    thresh: List([]),
    minMaxIndicador: Map({}),
    pontuacao_indicadores: '',
    teia: List([]),
});

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.dados.FETCH_MIN_MAX_INDICADOR: {
            return state.merge(action.payload);
        }

        case types.dados.RESET_MIN_MAX_INDICADOR: {
            return state.merge({ minMaxIndicador: Map({}) })
        }

        case types.reset.DADOS: {
            return state.merge({
                open: false,
            });
        }
        case types.reset.INDICADORES: {
            return state.merge({
                open: false,
                data: List([
                    Map({})
                ])
            })
        }

        case types.ucs.UCPROPS_PENDING:
        case types.ucs.UCPROPS_FULFILLED: {
            return state;
        }

        case types.dados.TOGGLE: {
            let dataPeriodo;

            if (action.payload.data["comMitigacao"] !== undefined
                && action.payload.data["comMitigacao"]["dados"] !== undefined
                && action.payload.data["comMitigacao"]["dados"][0]["ano"] < 1000) {


                if (state.get("periodo") === "historico") {
                    dataPeriodo = action.payload.data["comMitigacao"];
                    state = state.merge({ periodo: "comMitigacao" })
                }
                else {
                    dataPeriodo = action.payload.data[state.get("periodo")];
                }

            }
            else {
                dataPeriodo = action.payload.data[state.get("periodo")];
            }

            let periodo = state.get("periodo");
            if (!dataPeriodo) {
                dataPeriodo = action.payload.data["historico"];
                periodo = "historico";
            }
            let slider;
            let sliderVal;

            let indicadores = action.payload.data["indicadores"] ? action.payload.data : false;
            dataPeriodo = dataPeriodo ? dataPeriodo : indicadores;

            let data = action.payload.data;

            if (dataPeriodo) {

                // evita repetir o thresh dos dados que está setado no state que é > 1
                if (dataPeriodo["thresh"] !== undefined && state.get("thresh") !== undefined && state.get("thresh").size > 0 && state.get("thresh").get(0) < 1) {
                    dataPeriodo["thresh"] = state.get("thresh").toJS();
                }

                let thresh = dataPeriodo["thresh"];
                // Pega o primeiro e ultimos valores para setar o default do Slider
                if (thresh !== undefined) {
                    slider = List([thresh[0], thresh[thresh.length - 1]]);
                }

                data = dataPeriodo;

            }
            else if (action.payload.data["thresh"] && action.payload.data["dados"]) {
                let catMap = new Set([]);
                let dataDados = action.payload.data["aps"];
                for (let key in dataDados) {
                    for (let keyId in dataDados[key]) {
                        catMap = catMap.add(keyId);
                    }
                }
                catMap = catMap.toList().sort((a, b) => { return a > b });
                slider = {};
                slider["dados"] = catMap;
                sliderVal = List([parseInt(catMap.get(0), 10)]);
            }

            return state.merge({
                periodo: periodo,
                rawData: action.payload.data,
                open: true,
                visibility: false,
                data: data,
                slider: slider,
                sliderVal: sliderVal,
                texto: action.payload.texto,
                error: null,
                thresh: data["thresh"],
            });
        }

        case types.dados.UCS_POR_BUFFER: {
            return state.merge({
                open: true,
                visibility: false,
            });
        }

        case types.dados.MAPAS: { // são os mapas de governança e econômico (ex.: presença de getor, getão integrada, cadeias de Sociobio ...)
            let data = action.payload.data;
            let obj_legenda = data['dado']['legenda'];
            let obj_valores = data['dado']['valores'];
            let mapa_info = data['dado']['mapa'];

            let legenda = Object.keys(obj_legenda).map((key) => obj_legenda[key]); // convert para array

            let total_item_legenda = legenda.reduce((total, item) => { return item['fim'] >= 0 ? total + 1 : total }, 0);

            // criar as cores
            let colors = interpolateColors("rgb(75 150 164)", "rgb(144, 110, 136)", total_item_legenda);

            /*
              a partir da legenda monta o mapa de governança ou econômico selecionado
              com label, intervalos de valores, rgb e ucs_por_grupo
              ucs_por_grupo nesse "forEach" é iniciado em 0, no "map" seguinte é somado em cada tipo de dado,
              assim obtendo o total para cada tipo de dado
            */
            let mapa = [], i = 0, rgb_sem_informacao = [237, 222, 194];
            legenda.forEach((item, index) => {
                if (item['nome'] === "nan") return;
                let rgb = rgb_sem_informacao;
                if (parseFloat(item['fim']) === -3) {
                    rgb = [0, 0, 0]; // Sem acesso
                }
                else if (parseFloat(item['fim']) === -2) {
                    rgb = [80, 80, 80]; // Não se aplica
                }
                else if (parseFloat(item['fim']) !== -1) {
                    rgb = colors[i]; // que contém alguma informação
                    i++
                }
                mapa.push({ label: item['nome'], inicio: parseFloat(item['inicio']), fim: parseFloat(item['fim']), rgb: rgb, ucs_por_grupo: 0 });
            });

            /*
              cria um objeto em que cada UC tem um valor, um rgb correspondente ao valor e um label correspondente ao valor
            */
            let dados = {}, label_sem_informacao = 'Sem informação';
            Object.keys(obj_valores).forEach((key) => {
                let value = obj_valores[key];

                let rgb = rgb_sem_informacao, label = label_sem_informacao; // default
                mapa.forEach((item, i) => {
                    if ((value === item['fim']) || (value >= item['inicio'] && value < item['fim'])) {
                        rgb = !item['rgb'] ? rgb : item['rgb'];
                        label = item['label'];
                        mapa[i]['ucs_por_grupo']++;
                        dados[key] = { value: value, rgb: rgb, label: label }
                    }
                });
            });

            data = { dados, mapa, rgb_sem_informacao, label_sem_informacao, mapa_info };

            return state.merge({
                open: true,
                visibility: false,
                data: data,
                texto: action.payload.texto,
                ucNome: action.payload.texto,
                error: null,
            });
        }

        case types.dados.PONTUACAO_INDICADORES: {
            return state.merge({
                pontuacao_indicadores: { data: action.payload.data },
            });
        }

        case types.slider.PERIODO_CHANGE: {
            let periodo = state.get("rawData").get(action.payload.periodo)

            if (periodo !== undefined && state.get("thresh") !== undefined && state.get("thresh").size > 0) {
                periodo = periodo.set("thresh", state.get("thresh"))
            }

            let slider = {};

            if (periodo !== undefined && state.get("thresh") !== undefined) {
                let thresh = periodo.get("thresh").toJS();

                slider = List([thresh[0], thresh[thresh.length - 1]]);
            }

            return state.merge({
                data: periodo,
                slider: slider,
                periodo: action.payload.periodo,
            });
        }

        case types.feature.UPDATE_THRESH: {
            let data = state.get("data");
            data = data.set("thresh", action.payload);
            return state.merge({
                data: data,
                sliderVal: [action.payload.get(1), action.payload.get(2)],
                thresh: action.payload,
            });
        }

        case types.feature.UPDATE_SLIDER_VAL: {
            return state.merge({
                sliderVal: action.payload,
            });
        }

        case types.dados.REJECTED: {
            return state.merge({
                error: action.payload,
            });
        }

        case types.panel.PERIODO_INICIO: {
            return state.set('inicio', action.payload);
        }

        case types.panel.PERIODO_FIM: {
            return state.set('fim', action.payload);
        }

        case types.panel.CLOSE: {
            return state.merge({
                open: false,
                visibility: true,
            });
        }

        case types.panel.OPEN: {
            return state.merge({
                open: true,
                visibility: false,
            });
        }

        case types.dados.TEIA: {
            return state.merge(action.payload);
        }

        default:
            return state;
    }
}
