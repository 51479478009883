/**
 * Created by rbarcante on 9/8/17.
 */
import { Map } from "immutable";
import * as types from "../constants/ActionTypes";

const INITIAL_STATE = Map({
    layersAtivas: Map({
        map: true,
        topoJsonUcs: true,
    }),
    layerToggle: null,
    desativa: "",
    errorDesativa: "",
});

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.layers.TOGGLE: {
            return state.merge(action.payload);
        }
        case types.layers.DESATIVA: {
            return state.merge({
                type: action.type,
                desativa: action.payload,
                layersAtivas: state.get('layersAtivas').set(action.payload, false),
            });
        }
        case types.map.LOAD_ERROR_DESATIVA: {
            return state.merge({ errorDesativa: action.payload });
        }
        case types.reset.DADOS:
        case types.reset.INDICADORES: {
            return state.merge(INITIAL_STATE);
        }
        default:
            return state;
    }
};
