/**
 * Created by rbarcante on 9/8/17.
 */
import { Map } from "immutable";
import * as types from "../constants/ActionTypes";

export default function reducer(state=Map({
    lang: "pt_BR",
    dict: new Map({
        nav:{
            indicadores: "Explore a Plataforma",
            calculadora: "Calculadora",
            relatorios: "Relatórios",
            dados: "Fonte de Dados ",
            sobre: "Entenda a Plataforma",
            redes: "Redes",
            search: "Procure uma UC pelo nome",
        },
        leftBox:{
            escolhaIndicador: "Escolha um indicador...",
            pesquisar: "Pesquise...",
            indice: "Indice Geral",
            dados: "Dados",
            indicadores: "Indicadores",
            relatorioPorUc: "Relatório por UC",
            /* Clima */
            clima: "Ameaças climáticas",
            histTemp: "Histórico de Temperatura",
            histChuva: "Histórico de Chuva",
            histSeca: "Seca",
            histFogo: "Histórico de Fogo",
            aumentoTemperatura: "Aumento de Temperatura",
            mudancaChuva: "Mudança de Chuva",
            historicoSeca: "Risco Seca",
            riscoFogo: "Risco de Fogo",
            spei: "Risco de Seca",
            /* Antropicos */
            antropica: "Ameaças antrópicas",
            histDesmat: "Desmatamento",
            projetosHidreletricos: "Projetos Hidrelétricos",
            //potencialMineracao: "Potencial de Mineração",
            infraestrutura: "Infraestrutura",
            estradasAntropicas: "Proximidade de Estradas",
            sedesMunicipaisAntropicas: "Sedes Municipais",
            areaAmeaca: "% de Área Ameaçada",
            numAmeaca: "Número de Ameaças",
            historicoDeTemperatura: "Histórico de temperatura",
            temperaturaFutura: "Temperatura futura",
            historicoDePrecipitacao: "Histórico de precipitação",
            precipitacaoFutura:"Chuva futura",
            historicoDeSeca: "Risco de seca",
            secaFutura:"Seca futura",
            historicoFogo: "Histórico de fogo",
            historicoDeFogoHeatMap: "Histórico de fogo - Mapa de calor",
            distanciaDeEstradas: "Distância de estradas",
            atracaoUrbana: "Atração urbana",
            distanciaDeCidades: "Distância de cidades",
            distanciaDeHidreletricas: "Distância de hidrelétricas",
            distanciaDoDesmatamento: "Distância do desmatamento",
            potencialMineracao: "Potencial de Mineração",
            percentualMineracao: "Percentual de Mineração",
            proximidadeDesmatamento: "Proximidade de Desmatamento",
            proximidadeHidreletricas: "Proximidade de Hidrelétricas",
            proximidadeRodovias: "Proximidade de Estradas",
            proximidadeSedes: "Proximidade de Cidades",
            desmatamentoPorAno: "Desmatamento por ano",
            dadosAntropicos: "Dados antrópicos",
            dadosClimatico: "Dados climáticos",
            /* Governanca */

            /* 2015 */
            gestor: "Gestor",
            distanciaGestorUc: "Distância gestor a UC",
            cadastroFamalias: "Cadastro de famílias",
            portariado: "Portariado",
            criadoOnzeAnosOuMenos: "Criado/revis 2005 e depois",
            criado: "Criado",
            criadoCincoAnosOuMenos: "Criado/renov 2011 e depois",
            regimentoInterno: "Regimento Interno",
            instrumentosGestaoSemPop: "Instrumentos de Gestão - PI e US s/p",
            instrumentosGestaoComPop: "Instrumentos de Gestão - PI e US c/p",
            sobreposicaoApsEPas: "Sobreposição com APs e PAs",
            perimetroMosaicoAps: "% perímetro em mosaico APs",
            recursosFinanceirosInstitucionais: "Recursos financeiros institucionais - PI",
            fasesDoArpa: "Fases do ARPA",
            compensacaoAmbiental: "Compensação ambiental",
            participacaoAtoresSociaisComPop: "Participação de atores sociais - PI e US c/p",
            diversidadeConselhoGestor: "Diversidade no Conselho Gestor",
            representacaoDireta: "% representação direta (Base)",
            percGoverno: "% governo",
            outrasSocCivEPesq: "% outras soc. civ. e pesq",
            engajamentoSocialBenef: "Engajamento social beneficiários",
            participacaoBenefReuniaoCG: "Participação benef em reunião CG",
            participacaoAtoresSociaisSemPop: "Participação de atores sociais - PI e US s/p",
            /* end 2015 */

            capacidadeGovernanca: "Capacidade de Governança",
            estruturaGestao: "Estrutura de gestão",
            instrumentosGestao: "Instrumentos de Gestão",
            segurancaTerritorial: "Segurança Territorial",
            integridadeTerritorial: "Integridade Territorial",
            investimentos: "Investimentos",
            participacaoSocial: "Participação Social",
            participacaoAtoresSociais: "Participação de atores sociais",
            presencaGestorUc: "Presença de gestor da UC",
            tempoViagemGestorUc: "Tempo de viagem do gestor à UC",
            sedeFisicaParaGestaoUc: "Sede física para gestão da UC",
            estruturaIntegradaGestao: "Estrutura integrada de gestão",
            planoManejo: "Plano de Manejo",
            conselhoGestor: "Conselho Gestor",
            planoAcao: "Plano de Ação",
            termoCompromisso: "Termo de Compromisso (*PI)",
            ucsUsoSustentavelPopulacaoTradicional: "UCs de uso sustentáveis com população tradicional ",
            acordoGestao: "Acordo de Gestão",
            cdru: "CDRU",
            perfilBeneficiarios: "Perfil de beneficiários",
            cadastroBeneficiarios: "Cadastro de beneficiários",
            regularizacaoFundiaria: "Regularização Fundiária",
            sobreposicaoAreasProtegidas: "Sobreposição com Áreas Protegidas",
            ameacaDesafetacao: "Ameaça de Desafetação",
            vizinhancaComAreasProtegidas: "Vizinhança com Áreas Protegidas",
            arpaFases: "ARPA ( Fase sI, II e III )",
            recursosInstitucionaisOutrosProjetos: "Recursos institucionais de outros projetos",
            projetosOutrosAtoresUc: "Projetos de outros atures na UC",
            diversidadeAtoresCg: "Diversidade de atores no CG",
            numAtoresRedeGovernanca: "N. Atores na rede de governança",
            diversidadeTemasAtuacao: "Diversidade de temas de atuação",
            /* Economico */
            cadeiasSociobio: "Cadeias da sociobiodiversidade",
            governancaDasCadeiasSociobio: "Governança das cadeias da sociobiodiversidade",
            acessoAoMercado: "Acesso ao mercado",
            infraestruturaDeProducao: "Infraestrutura de produção",

            desenvEconom: "Potencial Econômico",
            indiceDesenvEconomico: "Índice de desenvolvimento econômico",
            ativProdComercFamilias: "Atividades produtivas e comercializadas pelas familias",
            extratVegNaoMadeira: "Extrativismo vegetal não madeireiro",
            extrativismoMadeira: "Extrativismo madeireiro",
            pesca: "Pesca",
            agricultira: "Agricultura",
            producaoAnimaisPeqPorte: "Produção de animais de pequeno porte",
            pecuaria: "Pecuária",
            artesanato: "Artesanato",

            diversidadeDeProdutos: "Diversidade de produtos",
            produtosExtrativistas: "Produtos extrativistas",
            produtosPesqueiros: "Produtos pesqueiros",
            produtosAgricolas: "Produtos agrícolas",
            numGadoFamilia: "Num. gado/família",

            atuacaoOrganizacoesColetivas: "Atuação de organizações coletivas",
            vendaProducao: "Venda produção",
            beneficiamento: "Beneficiamento",
            assistenciaTecnica: "Assistência técnica",

            proximidadeMercado: "Proximidade ao mercado",
            distanciaSedesMunicipais: "Distância a sedes municipais",
            distanciaEstradas: "Distância a estradas",
            distanciaRiosNavegaveis: "Distância a rios navegáveis",

            acessoPoliticasPublicas: "Acesso a políticas públicas",
            editaisGovernamentais: "Editais governamentais",
            beneficiosBolsaVerde: "Beneficios/Bolsa verde",
            acessoCredito: "Acesso à crédito",

            producaoExtrativistaMunicipios: "Produção extrativista nos municípios",
            producaoAcai: "Produção de açaí",
            producaoBorracha: "Produção de borracha",
            producaoCastanha: "Produção de castanha"
        },
        mapasApoio:{
            mapasDeApoio: 'Mapas de Apoio',
            definir: '?',
            mineracao: "Mineração",
            divisaoPolitica: "Divisão Política",
            estadosAmaz: "Estados",
            limitesMunicipais: "Limites Municipais",
            areasProtegidas: "Áreas Protegidas",
            unidadesConservacaoSustentavel: "Unidades de Conservação (Uso Sustentável)",
            unidadesConservacaoProtecao: "Unidades de Conservação (Proteção Integral)",
            jurisdicao: "Esfera",
            territoriosIndigenas: "Territórios Indígenas",
            crsIcmbio: "CRs ICMBio",
            aspectosNaturais: "Aspectos Naturais",
            biomas: "Biomas",
            fitofisionomias: "Fitofisionomias",
            tiposDeSolo: "Tipos de Solo",
            riosPrincipais: "Rios Principais",
            subBacias: "Sub Bacias",
            usoDoSolo: "Uso do Solo",
            desmatamento: "Desmatamento",
            usoDoSoloTerraClass: "Terraclass",
            empreendimentos: "Empreendimentos",
            sedesMunicipais: "Sedes Municipais",
            estradas: "Estradas",
            estradasEstaduais: "Estradas Estaduais",
            estradasFederais: "Estradas Federais",
            estradasPlanejadas: "Estradas Planejadas",
            polosMadeireiros: "Polos Madeireiros",
            portos: "Portos",
            hidreletricas: "Hidrelétricas",
            reservatorios: "Reservatórios",
            termeletricas: "Termelétricas",
            linhasDeTransmissao: "Linhas de Transmissão",
            dadosGovernanca: "Dados de governança",
            dadosDesenvolvimentoEconomico: "Dados de desenvolvimento econômico",
        },
        panelBody:{
            semData: "Não há dados para a seleção atual, por favor selecione um dado à ser exibido"
        },
        alert:{
            tentarNovamente: "Tentar novamente",
            erroExecuçao: 'Desculpe o transtorno, não foi possível executar essa ação no momento.',
            selecioneUC: 'Por favor selecione uma Unidade de Conservação',
            erroDadoIndisponiveis: 'Desculpe o transtorno, os dados ainda estão em análise e serão disponibilizados em breve.',
            ok: "Ok",

        },
        geral:{
            legenda: 'Legenda',
/*            distanciaDeEstradasInfluencia: 'INFLUÊNCIA DAS ESTRADAS',
            distanciaDeHidreletricasInfluencia: 'INFLUÊNCIA DAS HIDRELÉTRICAS',
            atracaoUrbanaInfluencia: 'INFLUÊNCIA DAS CIDADES',*/
            pesquisa: 'Pesquisa',
            lavra: 'Lavra',
            licenciamento: 'Licenciamento',
            extracao: 'Extração',
            vulneraveis: 'Vulneráveis',
            parcialmenteVulneraveis: 'Parcialmente vulneráveis',
            outras: 'Outras',
            totalmenteVulneraveis: 'Vulneráveis',
            parcialmenteVulneravies: 'parcialmente vulneráveis'
        }
    }),
}),action) {
    switch (action.type){
         case types.i18n.change:{
            return state.merge(action.payload);
        }
        default:
            return state;
    }
}
