/**
 * Created by rbarcante on 9/6/17.
 */
import { Map, List } from "immutable";
import * as types from "../constants/ActionTypes";

const DEFAULT_UC = Map({
    features: List([
        Map({
            properties: Map({ header: 'Amazônia' })
        })
    ])
});

const DEFAULT_FILTRO = Map({
    uc: [],
    estado: [],
    jurisdicao: [],
    tipouso: [],
});

const RESET_STATE = Map({
    uc: DEFAULT_UC,
});

const INITIAL_STATE = RESET_STATE.merge(
    Map({
        ucsSearchBox: [],
        filtro: DEFAULT_FILTRO,
    })
);

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.ucs.RESET_UCFILTRO: {
            return state.merge(
                Map({
                    filtro: DEFAULT_FILTRO,
                })
            );
        }

        case types.ucs.UCFILTRO_FULFILLED: {
            return state.merge(action.payload);
        }

        case types.ucs.UCPROPS_FULFILLED: {
            // seta default se for objeto vazio
            if (Object.keys(action.payload).length === 0
                && action.payload.constructor === Object) {
                return state.merge({
                    uc: DEFAULT_UC,
                })
            }
            return state.merge({
                uc: action.payload,
            });
        }

        case types.ucs.UCPROPS_REJECTED: {
            return state.merge(action.payload);
        }

        case types.ucs.POPULATE_SEARCH_BOX: {
            return state.set('ucsSearchBox', action.payload);
        }

        case types.reset.DADOS:
        case types.reset.INDICADORES: {
            return state.merge(RESET_STATE);
        }

        default:
            return state;
    }
};