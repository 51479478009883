/**
 * Created by rbarcante on 9/28/17.
 */
import { Map } from "immutable";
import * as types from "../constants/ActionTypes";

export default function reducer(state=Map({
    error: null,
    fetching:false,
    fetched:false,
}),action) {
    switch (action.type) {
        case types.map.LOAD_ERROR:
        case types.ucs.REJECTED:
        case types.dados.UC_UNDEFINED:
        case types.ucs.UCPROPS_REJECTED:
        case types.dados.REJECTED:{
            return state.merge({fetching:false,fetched:false,error:action.payload})
        }
        case types.layout.START_LOADING:
        case types.map.LOAD_START:
        case types.dados.PENDING:
        case types.ucs.UCPROPS_PENDING:{
            return state.merge({fetching:true,fetched:false})
        }
        case types.layout.DONE_LOADING:
        case types.map.LOAD_END:
        case types.ucs.UCPROPS_FULFILLED:
        case types.dados.TOGGLE:{
            return state.merge({fetching:false,fetched:true})
        }
        case types.alert.ALERT_RESET_ERROR:{
            return state.merge({fetching:false,fetched:false,error:null})
        }
        default:
            return state;
    }
}