import React from "react";
import { Route, Switch } from "react-router-dom"

import asyncComponent from "../components/AsyncComponent";

const Landing = asyncComponent(() => import("../components/Landing"));
const Layout = asyncComponent(() => import("../components/Layout"));
const Redes = asyncComponent(() => import("../components/Redes"));

export  default class App extends React.Component {
    render(){
        setTimeout(() => {
            if (window.ga) {
                window.ga('gtag_UA_129894453_1.send', 'pageview', {
                    page: window.location.pathname
                });
            }
        }, 500);

        return (
            <div>
                <Switch>
                    <Route exact={true} path="/" component={Landing}/>
                    <Route path="/plataforma" component={Layout}/>
                    <Route path="/redes/:idUc?" component={Redes}/>
                </Switch>
            </div>
        )
    }

}
