/**
 * Created by gamoridev on 27/2/19.
 */
import { Map, List } from "immutable";
import * as types from "../constants/ActionTypes";

export default function reducer(state=Map({
    uc: Map({}),
    org: Map({}),
    nodes: List([]),
    links: List([]),
    nodesExtrativistas: List([]),
    linksExtrativistas: List([])
}),action) {
    switch (action.type){
        case types.redes.FETCH_REDES_UC:{
            return state.merge({
                nodes: action.payload.data['nodes'],
                links: action.payload.data['links'],
                uc: Map({
                    id: action.payload.uc['value'],
                    nome: action.payload.uc['label'],
                }),
                org: Map({}),
            });
        }
        case types.redes.FETCH_REDES_ORG:{
            return state.merge({
                nodes: action.payload.data['nodes'],
                links: action.payload.data['links'],
                org: Map({
                    id: action.payload.org['value'],
                    nome: action.payload.org['label'],
                }),
                uc: Map({}),
            });
        }
        case types.redes.FETCH_REDES_EXTRATIVISTAS:{
            return state.merge({
                nodesExtrativistas: action.payload.data['nodes'],
                linksExtrativistas: action.payload.data['links'],
            })
        }
        case types.redes.CLEAR_REDES:{
            return state.merge({
                nodes: List([]),
                links: List([]),
                uc: Map({}),
                org: Map({})
            })
        }
        default:
            return state;
    }
}