
import { Map } from "immutable";
import * as types from "../constants/ActionTypes";

export default function reducer(state=Map({
    slider: "",
    ucsPorBuffer: "",
    itemsChecked: "",
    desmatePorAno: "",
    proporcaoDesmateUcs: "",
    anoDesmateAtual: ""
}),action) {
    switch (action.type){
        case types.slider.LAYER_ANUAL:
        case types.slider.LAYER_DISTANCE:
        case types.slider.LAYER_ANUAL_CHANGE:
        case types.slider.LAYER_DISTANCE_CHANGE:
          return state.merge({slider: action.payload});
        case types.dados.UCS_POR_BUFFER:
          return state.merge({ucsPorBuffer: action.payload, itemsChecked: action.itemsChecked});
        case types.slider.DATA_ANUAL:
        case types.slider.DATA_ANUAL_CHANGE:
          return state.merge({desmatePorAno: action.payload, proporcaoDesmateUcs: ""});
        case types.dados.PROPORCAO_DESMATE_UCS:
          return state.merge({proporcaoDesmateUcs: action.payload});
        case types.dados.ANO_DESMATE_ATUAL:
          return state.merge({anoDesmateAtual: action.payload});
        default:
          return state;
    }
}