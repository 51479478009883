/**
 * Created by rbarcante on 9/6/17.
 */
import { combineReducers } from "redux";

import action from "./action";
import uc from "./ucsReducer";
import leftBox from "./leftBoxReducer";
import layers from "./layersReducer";
import sliderLayer from "./sliderLayerReducer";
import map from "./mapReducer";
import panel from "./panelReducer"
import layout from "./layoutReducer"
import i18n from "./i18nReducer"
import redes from "./redesReducer"

export default combineReducers({
    action,
    uc,
    leftBox,
    layers,
    sliderLayer,
    map,
    panel,
    layout,
    i18n,
    redes,
})