/**
 * Created by rbarcante on 9/8/17.
 */

export var leftBox = {
  OPEN: "LEFT_BOX_OPEN",
  CLOSE: "LEFT_BOX_CLOSE",
  CLIMA: "LEFT_CLIMA_OPEN",
  GOVERNANCA: "LEFT_GOVERNANCA_OPEN",
  ANTROPICA: "LEFT_ANTROPICA_OPEN",
  DESENVECONOM: "LEFT_DESENVECONOM_OPEN",
  chuvaAnual: "CHUVA_ANUAL_ATIVA",
  dados: "DADOS_ATIVA",
  indicadores: "INDICADORES_ATIVA",
};

export var ucs = {
  UCSTOPO_PENDING: "FETCH_UCSTOPO_PENDING",
  UCSTOPO_FULFILLED: "FETCH_UCSTOPO_FULFILLED",
  UCSTOPO_REJECTED: "FETCH_UCSTOPO_REJECTED",
  UCPROPS_PENDING: "FETCH_UCPROPS_PENDING",
  UCPROPS_FULFILLED: "FETCH_UCPROPS_FULFILLED",
  UCPROPS_REJECTED: "FETCH_UCPROPS_REJECTED",
  POPULATE_SEARCH_BOX: "POPULATE_SEARCH_BOX",
  UCFILTRO_FULFILLED: "UC_FILTRO_FULFILLED",
  RESET_UCFILTRO: "RESET_UCFILTRO",
};

export var slider = {
  LAYER_ANUAL: "SLIDER_LAYER_ANUAL",
  LAYER_ANUAL_CHANGE: "SLIDER_LAYER_ANUAL_CHANGE",
  LAYER_DISTANCE: "SLIDER_LAYER_DISTANCE",
  LAYER_DISTANCE_CHANGE: "SLIDER_LAYER_DISTANCE_CHANGE",
  DATA_ANUAL: "SLIDER_DATA_ANUAL",
  DATA_ANUAL_CHANGE: "SLIDER_DATA_ANUAL_CHANGE",
  PERIODO_CHANGE: "PERIODO_CHANGE",
};

export var layers = {
  TOGGLE: "TOGGLE_LAYERS",
  DESATIVA: "DESATIVA_LAYERS",
};

export var feature = {
  TOGGLE: "TOGGLE_FEATURES",
  UPDATE_THRESH: "UPDATE_THRESH",
  UPDATE_SLIDER_VAL: "UPDATE_SLIDER_VAL",
  MAIS_DESMATOU: "FEATURE_MAIS_DESMATOU",
};

export var panel = {
  OPEN: "PANEL_OPEN",
  CLOSE: "PANEL_CLOSE",
  CLIMA: "PANEL_CLIMA_OPEN",
  ARVORE: "PANEL_ARVORE_OPEN",
  PERIODO_INICIO: "PANEL_PERIODO_INICIO",
  PERIODO_FIM: "PANEL_PERIODO_FIM",
  TAB_VALUE: "PANEL_TAB_VALUE",
};

export var map = {
  LOAD_START: "TILE_LOAD_START",
  LOAD_END: "TILE_LOAD_END",
  LOAD_ERROR: "TILE_LOAD_ERROR",
  LOAD_ERROR_DESATIVA: "TILE_LOAD_ERROR_DESATIVA",
  SET_MAP: "SET_APP_MAP",
};

export var dados = {
  CHECK_BOXES: "CHECK_BOXES_FOR_FILTRO",
  TOGGLE: "TOGGLE_DADOS",
  REJECTED: "DADOS_REJECTED",
  PENDING: "DADOS_PENDING",
  UC_UNDEFINED: "UC_UNDEFINED",
  INDICADORES: "DISPATCH_INDICADORES",
  INDICE_UC: "DISPATCH_INDICE_UC",
  UCS_POR_BUFFER: "DADOS_UCS_POR_BUFFER",
  FETCH_MIN_MAX_INDICADOR: "FETCH_MIN_MAX_INDICADOR",
  RESET_MIN_MAX_INDICADOR: "RESET_MIN_MAX_INDICADOR",
  PROPORCAO_DESMATE_UCS: "DADOS_PROPORCAO_DESMATE_UCS",
  ANO_DESMATE_ATUAL: "ANO_DESMATE_ATUAL",
  ORIGIN_DATA: "ORIGIN_DATA",
  PARAMS: "PARAMS",
  MAPAS: "MAPAS_DADOS", // são os mapas de governança e econômico (ex.: presença de getor, getão integrada, cadeias de Sociobio ...)
  PONTUACAO_INDICADORES: "PONTUACAO_INDICADORES",
  TEIA: "FETCH_TEIA",
};

export var reset = {
  DADOS: "DADOS_RESET",
  INDICADORES: "INDICADORES_RESET",
};
export var alert = {
  ALERT_RESET_ERROR: "ALERT_RESET_ERROR",
};

export var i18n = {
  CHANGE: "CHANGE_LANGUAGE",
};

export var layout = {
  START_LOADING: "START_LOADING",
  DONE_LOADING: "DONE_LOADING",
};

export var redes = {
  CHANGE_ORG: "CHANGE_ORG",
  CHANGE_UC: "CHANGE_UC",
  FETCH_REDES_UC: "FETCH_REDES_UC",
  FETCH_REDES_ORG: "FETCH_REDES_ORG",
  FETCH_REDES_EXTRATIVISTAS: "FETCH_REDES_EXTRATIVISTAS",
  CLEAR_REDES: "CLEAR_REDES",
};

//export var url = 'https://api.staging.somuc.org.br';
export var url = "https://api.somuc.org.br";
//export var url = process.env.NODE_ENV === 'development' ? 'http://'+window.location.hostname+':9999' : 'https://api.'+window.location.hostname;
//export var mapServerUrl = process.env.NODE_ENV === 'development'? 'http://'+window.location.hostname :'http://mapserver.ipam.inf.br';

// o link "https://somuc.org.br/mapserver/" dentro do nginx será redirecionado para "http://mapserver.ipam.inf.br"
export var mapServerUrl = "https://somuc.org.br/mapserver";

export var COLORS = [
  "rgba(73, 174, 47, 1)",
  "rgba(239, 221, 93, 1)",
  "rgba(255,0,0, 1)",
];
